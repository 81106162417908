/* eslint-disable */
/* eslint-disable no-plusplus */
import short from 'short-uuid';
import { ValidationError } from 'yup';
import randomColors from 'randomcolor';
import dayjs from 'dayjs';
import lodashOmit from 'lodash/omit';
import { format } from 'date-fns';
import { AlertColor } from '@mui/material';
import moment from 'moment';
import CommHandler from './CommHandler';
import { config } from '../config';

// import TranslateFn, {
//   SASE_DefaultDateFormatOptions,
//   SASE_TimeFormatOptions,
//   SASE_TimeWithSecondsFormatOptions,
//   SASE_TimeWithTimezoneFormatOptions,
//   SASE_Year_FormatOptions,
//   SASE_Month_FormatOptions,
//   SASE_Month_Num_FormatOptions,
//   SASE_Day_FormatOptions,
//   SASE_TimeStyleFormatOptions,
//   SASE_TimeZoneNameFormatOptions,
// } from '../i18n/translate';
// import { isObj } from './isObject';

export enum AuthCountType {
  timeout = 'timeout',
  configfail = 'configfail',
}

export enum FieldTypes {
  string = 'string',
  enum = 'enum',
  int = 'int',
  boolean = 'boolean',
  long = 'long',
  float = 'float',
  timestamp = 'timestamp',
  array = 'array',
}

export const token = localStorage.getItem('token');

export const userId = localStorage.getItem('user_id');

export const email = localStorage.getItem('email');

export const department = localStorage.getItem('department');

export const getAuthHeader = {
  'x-idToken': token || '',
  'x-user_id': userId || '',
  'user': email || '',
  'x-department': department || '',
};

export function differenceSets(a: Set<unknown>, b: Set<unknown>): Set<unknown> {
  return new Set([...a].filter((x: unknown) => !b.has(x)));
}

export function unionSets(a: Set<unknown>, b: Set<unknown>): Set<unknown> {
  return new Set([...a, ...b]);
}

export function intersectionSet(a: Set<unknown>, b: Set<unknown>): Set<unknown> {
  return new Set([...a].filter((x: unknown) => b.has(x)));
}

export function isIn(a: Set<unknown>, b: Set<unknown>): boolean {
  return Array.from(a).every((item) => b.has(item));
}

export function eqSet(a: Set<unknown>, b: Set<unknown>): boolean {
  if (a.size !== b.size) {
    return false;
  }
  return isIn(a, b);
}

export function getCurrentTime(): number {
  return Date.now();
}

export function getNumberFromPixel(pixel: string): number {
  const re = /(\d+)px/i;
  const matches: any = pixel.match(re);
  return Number(matches[1]);
}

export function getObjectTrueCount(obj: Record<string, boolean>): number {
  return Object.values(obj)?.filter((value) => value === true).length;
}

export function mapToObject(map: Map<string, unknown>): unknown {
  const objectFromMap: Record<string, unknown> = {};
  map.forEach((value, key) => {
    if (value instanceof Map) {
      objectFromMap[key] = mapToObject(value);
    } else {
      objectFromMap[key] = value;
    }
  });
  return objectFromMap;
}

export const isObj = (variable: any): boolean => typeof variable === 'object' && variable !== null;

export function removeProperty(data: Record<string, unknown> | Array<Record<string, unknown>>, propertyName: string): unknown {
  if (Array.isArray(data)) {
    return data.map((item) => lodashOmit(item, propertyName));
  }
  if (isObj(data)) {
    return lodashOmit(data, propertyName);
  }
  return data;
}

export function isNil(value: unknown): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return value === undefined || value === null || Number.isNaN(value as any);
}

export function isNilOrEmptyString(value: unknown): boolean {
  return isNil(value) || value === '';
}

export function generateUUID(): string {
  return short.generate();
}

export function capitalizeFirstLetter(str: string | undefined): string {
  return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function formatDate(dateString: number): string {
  const dateObject = new Date(dateString);
  if (Number.isNaN(dateObject.getTime())) {
    return '';
  }
  return format(dateObject, 'yyyy-MM-dd');
}

export function mapSeverityToAlertColor(customSeverity: string | undefined): AlertColor {
  // Map your custom severity values to valid AlertColor values
  switch (customSeverity) {
    case 'success':
      return 'success';
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'info':
      return 'info';
    default:
      return 'info'; // Default to a valid value if none matches
  }
}

// export function formatToLocalTime(utcTimeInMs: number): string {
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeFormatOptions);
//   return `${time}`;
// }

// export function formatToLocalTimeWithSeconds(utcTimeInMs: number): string {
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeWithSecondsFormatOptions);
//   return `${time}`;
// }

// export function formatToLocalTimeWithTimezone(utcTimeInMs: number): string {
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeWithTimezoneFormatOptions);
//   return `${time}`;
// }

// export function formatToLocalDayMonth(utcTimeInMs: number): string {
//   const day = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Day_FormatOptions);
//   const month = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Month_FormatOptions);
//   return `${day} ${month}`;
// }

// export function formatToLocalDayMonthYear(utcTimeInMs: number): string {
//   const dayAndMonth = formatToLocalDayMonth(utcTimeInMs);
//   const year = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Year_FormatOptions);
//   return `${dayAndMonth}, ${year}`;
// }

// export function formatToLocalDayMonthYearTime(utcTimeInMs: number): string {
//   const dayMonthAndYear = formatToLocalDayMonthYear(utcTimeInMs);
//   const time = formatToLocalTime(utcTimeInMs);
//   return `${dayMonthAndYear} ${time}`;
// }

// export function formatToLocalMonthDayYearTime(utcTimeInMs: number): string | null {
//   if (isNil(utcTimeInMs)) {
//     return null;
//   }
//   const day = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Day_FormatOptions);
//   const month = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Month_FormatOptions);
//   const year = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Year_FormatOptions);
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeStyleFormatOptions);
//   return `${month} ${day}, ${year} ${time}`;
// }
// export function formatToLocalMonthDayYearTimeSlash(utcTimeInMs: number): string | null {
//   if (isNil(utcTimeInMs)) {
//     return null;
//   }
//   const date = format(new Date(utcTimeInMs), 'EEEE');
//   const day = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Day_FormatOptions);
//   const month = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Month_Num_FormatOptions);
//   const year = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Year_FormatOptions);
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeStyleFormatOptions);
//   return ` ${date} ${month}/${day}/${year} ${time}`;
// }

// export function formatToLocalDayMonthYearTimeWithSeconds(utcTimeInMs: number): string {
//   const dayMonthAndYear = formatToLocalDayMonthYear(utcTimeInMs);
//   const time = formatToLocalTimeWithSeconds(utcTimeInMs);
//   return `${dayMonthAndYear} ${time}`;
// }

// export function formatToLocalDateTime(utcTimeInMs: number): string {
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeFormatOptions);
//   const date = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_DefaultDateFormatOptions);
//   return `${date} ${time}`;
// }

// export function formatToDateTimeWithTimezone(utcTimeInMs: number): string {
//   const time = TranslateFn.getInstance().formatTime(
//     utcTimeInMs,
//     { ...SASE_TimeWithSecondsFormatOptions, ...SASE_TimeZoneNameFormatOptions },
//   );
//   const date = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_DefaultDateFormatOptions);
//   return `${date} ${time}`;
// }

export function formatToUTCDateMonthYear(timestamp: number): string {
  if (isNil(timestamp)) {
    return '--';
  }
  try {
    const date = new Date(timestamp).toUTCString().split(' ').splice(0, 4)
      .join(' ');
    return `${date}`;
  } catch (e) {
    return 'Invalid Date';
  }
}

// export function formatToLocalMonthDayTime(utcTimeInMs: number): string | null {
//   if (isNil(utcTimeInMs)) {
//     return null;
//   }
//   const day = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Day_FormatOptions);
//   const month = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Month_FormatOptions);
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, SASE_TimeStyleFormatOptions);

//   return `${month} ${day} ${time}`;
// }

// export function formatToLocalMonthDayTimeWithTimeZone(utcTimeInMs: number): string | null {
//   if (isNil(utcTimeInMs)) {
//     return null;
//   }
//   const day = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Day_FormatOptions);
//   const month = TranslateFn.getInstance().formatDate(utcTimeInMs, SASE_Month_FormatOptions);
//   const time = TranslateFn.getInstance().formatTime(utcTimeInMs, {
//     timeZoneName: 'short',
//   });

//   return `${month} ${day} ${time}`;
// }

// export const DATE_TIME_FORMAT_FUNCS = {
//   UTCDateMonthYear: formatToUTCDateMonthYear,
//   LocalDateTime: formatToLocalDateTime,
//   LocalDayMonthYearTimeWithSeconds: formatToLocalDayMonthYearTimeWithSeconds,
//   LocalMonthDayYearTime: formatToLocalMonthDayYearTime,
//   LocalDayMonthYearTime: formatToLocalDayMonthYearTime,
//   LocalDayMonthYear: formatToLocalDayMonthYear,
//   LocalDayMonth: formatToLocalDayMonth,
//   LocalTimeWithTimezone: formatToLocalTimeWithTimezone,
//   LocalTimeWithSeconds: formatToLocalTimeWithSeconds,
//   LocalTime: formatToLocalTime,
//   LocalMonthDayTime: formatToLocalMonthDayTime,
//   LocalMonthDayTimeWithTimeZone: formatToLocalMonthDayTimeWithTimeZone,
// };

export function getUniqueColorsByCount(count: number, seed?: string): string[] {
  return randomColors({
    luminosity: 'bright',
    count: count ?? 1000,
    seed: seed ?? '5',
  });
}

export function getUniqueValuesForPropertyFromObjectArray(items: Record<string, unknown>[], propertyName: string): string[] {
  const propertyValues = items?.filter((item) => !isNilOrEmptyString(item[propertyName])).map((item) => item[propertyName] as string) ?? [];
  return [...new Set(propertyValues)];
}

export function parseYupErrors(error: ValidationError): Record<string, string[]> {
  return error.inner.reduce((errObj: any, item: any) => {
    const errorObj = errObj;
    errorObj[item.path] = item.errors;
    return errorObj;
  }, {});
}

// filters nil values from array
export function filterNil(data: string[]): string[] {
  return data.filter((item: string) => !isNil(item));
}

export function doNothing(): void {
  // do nothing
}

/**
 * This function takes in an array of elements and splits them into column buckets <= maxColLength
 * @param arr - array of elements
 * @param maxColSize - the max amount of data points in any column. Alternative paradigm as amount of rows.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function splitArrayIntoColumns(arr: any[], maxColSize: number): unknown[][] {
  if (typeof maxColSize !== 'number') {
    return [arr];
  }
  return arr.reduce((prevValue, currVal, ind) => (ind % maxColSize !== 0 ? (prevValue[prevValue.length - 1].push(currVal), prevValue) : (prevValue.push([currVal]), prevValue)), []);
}

/**
 *returns a value as a percentage
 */
export const percentageChange = (current: number, previous: number): string | number => {
  if (current - previous === 0) {
    return 0;
  }
  if (previous === 0) {
    return 0;
  }
  return (((current - previous) / previous) * 100).toFixed(2);
};

/**
 * Checks if string is upper case, will return false if string char is not alphabetic
 */
export const isUpperCase = (str: string): boolean => str === str.toUpperCase() && str !== str.toLowerCase();

/**
 * Converts objects with camelcase keys to snakecase keys
 * * { siteType: "Third Party" } => { site_type: "Third Party" }
 */
export const urlToDataFormatter = (props: Record<string, unknown>): Record<string, unknown> => {
  const dataFormatted: Record<string, unknown> = {};
  Object.entries(props).forEach(([key, value]) => {
    let formattedKey = '';
    for (let i = 0; i < key.length; i += 1) {
      if (isUpperCase(key[i])) {
        formattedKey += `_${key[i].toLowerCase()}`;
      } else {
        formattedKey += key[i];
      }
    }
    dataFormatted[formattedKey] = value;
  });
  return dataFormatted;
};

export default function isEmpty(variable: any): boolean {
  if (variable === undefined || variable === null) {
    return true;
  }
  if (isObj(variable)) {
    return Object.keys(variable).length === 0;
  }
  if (Array.isArray(variable)) {
    return variable.length === 0;
  }
  if (typeof variable === 'string') {
    return variable === '';
  }
  return false;
}

const getFileName = (s3url: string) => {
  const parts = s3url.split('.s3.');
  const locationParts = parts[1].split('.amazonaws.com/');

  const encodedFileName = locationParts[1];
  const fileName = decodeURIComponent(encodedFileName);
  return fileName;
};

function getMimeTypeFromFilename(filename: any) {
  const extension = filename.split('.').pop().toLowerCase();
  let mimeType;

  switch (extension) {
    case 'jpg':
    case 'jpeg':
      mimeType = 'image/jpeg';
      break;
    case 'png':
      mimeType = 'image/png';
      break;
    case 'pdf':
      mimeType = 'application/pdf';
      break;
    default:
      mimeType = 'application/octet-stream';
  }
  return mimeType;
}

function base64toFile(base64String: string, filename: string, mimeType: string) {
  const base64Characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const base64ArrayBuffer = new Uint8Array(
    atob(base64String)
      .split('')
      .map((char) => base64Characters.indexOf(char)),
  );

  const blob = new Blob([base64ArrayBuffer], { type: mimeType });
  return new File([blob], filename, { type: mimeType });
}

export const getFiles = (file_path: any, files: any) => file_path.map((path: string, i: number) => {
  const fileName = getFileName(path);
  const mimeType = getMimeTypeFromFilename(fileName);
  return base64toFile(files[i], fileName, mimeType);
});

export const limitValueInRange = (value: number): number => Math.floor(Math.min(Math.max(value, 0), 100));

export const calculateDuration = (fromDateStr: string, toDateStr: string) => {
  const fromDate = new Date(fromDateStr);
  const toDate = toDateStr ? new Date(toDateStr) : new Date();

  const diffInMonths = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());
  const diffInDays = toDate.getDate() - fromDate.getDate();

  let months = diffInMonths;
  let days = diffInDays;

  if (days < 0) {
    months--;
    const daysInLastMonth = new Date(toDate.getFullYear(), toDate.getMonth(), 0).getDate();
    days = daysInLastMonth - fromDate.getDate() + toDate.getDate();
  }

  const yearStr = months >= 12 ? `${Math.floor(months / 12)} year${Math.floor(months / 12) > 1 ? 's' : ''}` : '';
  months %= 12;
  const monthStr = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';
  const dayStr = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';

  return `${yearStr} ${monthStr} ${dayStr}`.trim();
};

export const getUniqueObjectsBasedOnKey = (arr: any[], key: string): any[] => {
  if (isEmpty(arr)) {
    return [];
  }
  if (isNil(key)) {
    return arr;
  }

  return [...new Map(arr?.map((item) => [item?.[key], item])).values()];
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getMonth() > 2 ? currentDate.getFullYear() : currentDate.getFullYear() -1;
};

export const getFormattedDate = (dateString) => {
  const date = moment(dateString);

  // Get current time
  const now = moment();

  // Calculate the difference in minutes between now and the provided date
  const diff = now.diff(date, 'minutes');

  // Handle different cases based on the difference in time
  if (diff === 0) {
    return 'Now';
  }
  if (diff < 60) {
    return `${diff} min ago`;
  }

  // Check for today
  if (date.isSame(now, 'day')) {
    return `Today, ${date.format('LT')}`;
  }

  // Check for yesterday
  if (date.isSame(now.subtract(1, 'day'), 'day')) {
    return `Yesterday, ${date.format('LT')}`;
  }

  // Default format
  return `${date.format('MMM D - LT')}`;
};

export const fetchUnreadArtifacts = async () => {
  try {
    const currentUserId = localStorage.getItem('user_id');
    const response = await CommHandler.request({
      url: `${config.apiBaseUrl}/chats/getArtifactsWithUnreadChats?user_id=${currentUserId}`,
      method: 'GET',
      headers: getAuthHeader,
    });
    return response?.data;
  } catch (error) {
    console.error('Error fetching unread artifacts:', error);
    return null;
  }
};

export const markAsRead = async (artifact_id: string[]) => {
  try {
    const response = await CommHandler?.request({
      url: `${config.apiBaseUrl}/chats/markAsRead`,
      method: 'PUT',
      data: { artifact_ids: artifact_id },
      headers: getAuthHeader,
    });
    if (response?.status === 200) {
      fetchUnreadArtifacts();
    }
  } catch (error) {
    console.error('Error marking artifacts as read:', error);
  }
};

export const getCurrentQuarter = () => {
    const currentMonth = dayjs().month() + 1;
    if (currentMonth >= 4 && currentMonth <= 6) {
      return 1;
    } if (currentMonth >= 7 && currentMonth <= 9) {
      return 2;
    } if (currentMonth >= 10 && currentMonth <= 12) {
      return 3;
    }
    return 4;
  };
