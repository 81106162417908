export const formatArtifact = async (data: any, Category: any) => {
  const resultObj: any = {};
  const subCategoryFilter: any = {};
  await Category.map(({ id }: any) => {
    const dataRes: any = [];
    const subCatRes: any = [];
    data.map((element: any) => {
      const { name, id: subId, data: artifactData }: any = element;
      if (element.artifact_category_id === id) {
        dataRes.push(element);
        if (artifactData && artifactData.length > 0) {
          subCatRes.push({ name, id: subId });
        }
      }
      return {};
    });
    resultObj[id] = dataRes;
    const finalArr = [{ label: 'SUB-CATEGORIES', item: subCatRes }];
    subCategoryFilter[id] = finalArr;
    return {};
  });
  return { resultObj, subCategoryFilter };
};

export const filterRes = (status: any, subCategory: any, data: any) => {
  const finalRes: any = [];
  let result: any = data || [];
  const lowerCaseStatus = status?.map((item: string) => item.toLowerCase());
  if (status.length === 0 && subCategory.length === 0) {
    return data;
  }
  if (subCategory.length) {
    result = result.filter(({ name }: any) => subCategory.includes(name));
  }
  if (result.length !== 0 && status.length !== 0) {
    result.map((item: any) => {
      const {
        createdAt, name, string,
      } = item;

      const finalResult = string.filter(({ approved }: any) => lowerCaseStatus.includes(approved));
      if (finalResult.length !== 0) {
        const dataObj = {
          artifact_category_id: item.artifact_category_id,
          createdAt,
          name,
          string: [...finalResult],
        };
        finalRes.push(dataObj);
      }
      return {};
    });
  }
  return result.length !== 0 && status.length === 0 ? result : finalRes;
};

export function truncateText(str: string | any[], maxLength: number) {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
}

export const filterResList = (status: any, subCategory: any, data: any) => {
  const finalRes: any = [];
  let result: any = data || [];
  const lowerCaseStatus = status.map((item: string) => {
    if (item === 'PartiallyApproved') {
      return 'partiallyApproved';
    }
    return item.toLowerCase();
  });
  if (status.length === 0 && subCategory.length === 0) {
    return data;
  }
  if (subCategory.length) {
    result = result.filter(({ subCategoryName }: any) => subCategory.includes(subCategoryName));
  }
  if (result.length !== 0 && status.length !== 0) {
    result.map((item: any) => {
      const {
        createdAt, subCategoryName, subCategoryData,
      } = item;

      const finalResult = subCategoryData.filter(({ approved }: any) => lowerCaseStatus.includes(approved));
      if (finalResult.length !== 0) {
        const dataObj = {
          artifact_category_id: item.artifact_category_id,
          createdAt,
          subCategoryName,
          subCategoryData: [...finalResult],
        };
        finalRes.push(dataObj);
      }
      return {};
    });
  }
  return result.length !== 0 && status.length === 0 ? result : finalRes;
};

export const removeHtmlTags = (htmlString) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};
