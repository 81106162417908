/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Grid, ListItemText, ListItemAvatar, Paper,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileDetail from './components/ProfileDetail';
import { RootState } from '../../store/type';
import { User } from '../Dashboard/components/UserProfile/type';
import { StyledCustomizedDivider } from '../Dashboard/Dashboard.styled';
import { CustomeAvatar } from '../../components';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { Rankings } from '../Dashboard/components/Ranking';
import { UserProfiles } from '../Dashboard/components/UserProfile';
import KeyResponsibility from './components/KeyResponsibility';
import { getAuthHeader, getCurrentYear } from '../../utils/utils';
import { checkPermissions } from '../../utils/roles/permissionUtils';
import ShowClonnedUser from './components/ShowClonnedUser';

const Profile = () => {
  const selectUser = (state: RootState) => state.user.employee;
  const user: User = useSelector(selectUser);
  const RM = `${user?.profile?.managerID?.firstName} ${user?.profile?.managerID?.lastName}`;
  const location = useLocation();
  const empId: any = user.profile;
  const userID = empId?.user_id;
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear()
  )?.toString();

  const searchEmpId: any = location.state !== null ? location.state : userID;
  const [propData, setPropData] = useState(false);
  const [apiData, setApiData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [rankData, setRankData] = useState<any>({});
  const hasKRAPermission = checkPermissions('PMSMYPROFILE:SUBKRA', ['Read', 'Edit', 'Write', 'Delete'], true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response: any = await CommHandler.request({
          url: `${config.apiBaseUrl}/users/${searchEmpId}`,
          method: 'GET',
          headers: getAuthHeader,
        });
        setApiData(response?.data);
        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        return false;
      }
    }
    fetchData();
  }, [searchEmpId]);

  useEffect(() => {
    if (searchEmpId) {
      const fetchData = async () => {
        try {
          const response = await CommHandler.request({
            url: `${config.apiBaseUrl}/artifactPoints/user-rank?userId=${searchEmpId}`,
            method: 'GET',
            params: {
              year: currentYear,
            },
            headers: getAuthHeader,
          });
          if (response.data) {
            setRankData(response.data[0]);
          }
        } catch (error) {
          console.error('Error fetching rank:', error);
        }
      };
      fetchData();
      setPropData(true);
    }
  }, [searchEmpId, currentYear]);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  return (
    <Grid container item rowGap={2}>
      <>
        {propData ? (
          <Grid container item flexGrow={1}>
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                boxShadow: 'none',
                border: '1px solid #E7E7E7',
                borderRadius: '10px',
                flexWrap: 'wrap',
              }}
            >
              <Grid item xs={12} md={12} lg={3.5}>
                <UserProfiles data={apiData} />
              </Grid>
              <Grid xs={6} md={5} lg={3.5}>
                {searchEmpId && (
                <Rankings
                  currentBURank={rankData?.currentBURank || 0}
                  currentCompanyRank={rankData?.currentCompanyRank || 0}
                />
                )}
              </Grid>
              <StyledCustomizedDivider
                orientation="vertical"
                flexItem
              />
              <Grid
                container
                xs={6}
                md={7}
                lg={5}
                sx={{
                  paddingLeft: '25px',
                  whiteSpace: 'nowrap',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                }}
                justifyContent="start"
                spacing={6}
              >
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_location.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Location
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {apiData?.profile?.location}
                        {' '}
                      </span>
                                          )}
                  />
                </Grid>
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_repo.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Department
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {apiData?.profile?.department}
                        {' '}
                      </span>
                                          )}
                  />
                </Grid>
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_depa.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Reporting to
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {apiData?.managerDetails?.firstName}
                        {' '}
                        {apiData?.managerDetails?.lastName}
                      </span>
                      )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <Grid container item flexGrow={1}>
            <Paper
              elevation={1}
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                boxShadow: 'none',
                border: '1px solid #E7E7E7',
                borderRadius: '10px',
                flexWrap: 'wrap',
              }}
            >
              <Grid item xs={12} md={6} lg={5}>
                <UserProfiles />
              </Grid>
              <StyledCustomizedDivider
                orientation="vertical"
                flexItem
              />
              <Grid
                container
                xs={12}
                md={6}
                lg={7}
                sx={{ paddingLeft: '30px' }}
                justifyContent="start"
                spacing={6}
              >
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_location.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Location
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {user?.profile?.location}
                        {' '}
                      </span>
                                          )}
                  />
                </Grid>
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_repo.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Department
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {user?.profile?.department}
                        {' '}
                      </span>
                                          )}
                  />
                </Grid>
                <Grid container item xs="auto">
                  <ListItemAvatar>
                    <CustomeAvatar imageUrl="profile_depa.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '16px',
                          color: '#7B7B7B',
                        }}
                      >
                        Reporting to
                      </span>
                                          )}
                    secondary={(
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: '#1D1D1D',
                        }}
                      >
                        {RM}
                        {' '}
                      </span>
                                          )}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </>
      <ProfileDetail data={apiData} />
      <ShowClonnedUser apidata={apiData}/>
      {hasKRAPermission && (
          <KeyResponsibility />
      )}
    </Grid>
  );
};
export default Profile;
