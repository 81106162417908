/* eslint-disable */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

const DialogBoxforCloneApproval = (props:any) => {
  const { showConfirmationDialog, handleCancelConfirmation, handleConfirmSave } = props;

  return (
    <Dialog className="btn_style_prt" open={showConfirmationDialog}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to add these names to the clone for this financial year? This action is uneditable.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelConfirmation}>No</Button>
        <Button onClick={handleConfirmSave}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};


export default DialogBoxforCloneApproval;
